import { useCallback } from 'react';
import {
  setCommunityType,
  setFreeMint,
  setSortType,
} from 'store/features/raffleListSlice';
import { useAppDispatch } from 'store/hooks';

export function clearLocalData() {
  localStorage.removeItem('FreeMint');
  localStorage.removeItem('Filter');
  localStorage.removeItem('FilterProject');
  localStorage.removeItem('SortBy');
  localStorage.removeItem('MyPartial');
  localStorage.removeItem('MyPartialProject');
  localStorage.removeItem('ProjectFreeMint');
  localStorage.removeItem('ProjectSortBy');
  localStorage.removeItem('email');
  localStorage.removeItem('anticode');
  localStorage.removeItem('loginSocialType');
  localStorage.removeItem('lastFillEmail');
}

export function useResetFilterOptions() {
  const dispatch = useAppDispatch();

  const resetFilterOptions = useCallback(() => {
    dispatch(setFreeMint('0'));
    dispatch(setCommunityType('All'));
    dispatch(setSortType('Trending'));
  }, [dispatch]);

  return {
    resetFilterOptions,
  };
}
