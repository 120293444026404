import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { Raffle } from 'api/raffle/raffle_detail';
import { RaffleRegister } from 'api/raffle/raffle_register';

interface DiscordLimit {
  limitTime: number;
  limitMin: string;
  limitSec: string;
}

interface RaffleDetailsState {
  raffleInfo: Raffle;
  raffleRegisterResult: RaffleRegister;
  loading: boolean;
  entryCount: number;
  entries: number;
  isOwner: boolean;
  isAnnounce: boolean;
  hasWonMintWallets: string[];
  mintWallet: string;
  isOpenTwitterAutomate: boolean;
  discordUsername: string;
  twitterUsername: string;
  twitterAutoRequirement: boolean;
  emailAddr: string;
  validEmailAddr: number;
  discordLimit: DiscordLimit;
}

const initialState: RaffleDetailsState = {
  raffleInfo: {
    campaign: {
      id: 0,
      twitter_link: '',
      name: '',
      logo: '',
    },
    is_hide_community_logo: false,
    is_hide_project_logo: false,
    is_dc_new_raffle_announced: false,
    entry_count: 0,
    end_time: undefined,
    start_time: undefined,
    remaining_seconds: 0,
    whitelist_method: '',
    role_based_dc_invite_link: '',
    role_based_dc_server_name: '',
    type: '',
    more_info_for_winners: '',
    description: '',
    name: '',
    id: 0,
    vanity_url: '',
    requirement_is_wallet_balance_open: 0,
    requirement_is_dc_open: 0,
    requirement_is_tw_open: 0,
    requirement_is_wallet_allow_list_open: false,
    winner_count: 0,
    is_hide_entry_count: 0,
    status: '',
    requirement_is_wallet_nft_open: 0,
    owner: {
      address: '',
      discord_avatar: '',
      discord_username: '',
      twitter_avatar: '',
      twitter_username: '',
      twitter_write_permission: false,
    },
  },
  raffleRegisterResult: {
    validation: false,
    multiplier: 0,
    requirement_validations: [],
  },
  loading: false,
  entryCount: 0,
  entries: 1,
  isOwner: false,
  isAnnounce: false,
  hasWonMintWallets: [],
  mintWallet: '',
  isOpenTwitterAutomate: false,
  discordUsername: '',
  twitterUsername: '',
  twitterAutoRequirement: false,
  emailAddr: '',
  validEmailAddr: 0,
  discordLimit: {
    limitTime: 0,
    limitMin: '0',
    limitSec: '0',
  },
};

export const raffleDetailsSlice = createSlice({
  name: 'raffleDetailsSlice',
  initialState,
  reducers: {
    setRaffleInfo: (state, action: PayloadAction<Raffle>) => {
      state.raffleInfo = action.payload;
    },
    setRaffleRegisterResult: (state, action: PayloadAction<RaffleRegister>) => {
      state.raffleRegisterResult = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setEntryCount: (state, action: PayloadAction<number>) => {
      state.entryCount = action.payload;
    },
    setEntries: (state, action: PayloadAction<number>) => {
      state.entries = action.payload;
    },
    setIsOwner: (state, action: PayloadAction<boolean>) => {
      state.isOwner = action.payload;
    },
    setIsAnnounce: (state, action: PayloadAction<boolean>) => {
      state.isAnnounce = action.payload;
    },
    setHasWonMintWallets: (state, action: PayloadAction<string[]>) => {
      state.hasWonMintWallets = action.payload;
    },
    setRaffleMintWallet: (state, action: PayloadAction<string>) => {
      state.mintWallet = action.payload;
    },
    setIsOpenTwitterAutomate: (state, action: PayloadAction<boolean>) => {
      state.isOpenTwitterAutomate = action.payload;
    },
    setRaffleDiscordUsername: (state, action: PayloadAction<string>) => {
      state.discordUsername = action.payload;
    },
    setRaffleTwitterUsername: (state, action: PayloadAction<string>) => {
      state.twitterUsername = action.payload;
    },
    setTwitterAutoRequirement: (state, action: PayloadAction<boolean>) => {
      state.twitterAutoRequirement = action.payload;
    },
    setEmailAddr: (state, action: PayloadAction<string>) => {
      state.emailAddr = action.payload;
    },
    setValidEmailAddr: (state, action: PayloadAction<number>) => {
      state.validEmailAddr = action.payload;
    },
    setDiscordLimit: (state, action: PayloadAction<DiscordLimit>) => {
      state.discordLimit = action.payload;
    },
  },
});

export const {
  setRaffleInfo,
  setRaffleRegisterResult,
  setLoading,
  setEntryCount,
  setEntries,
  setIsOwner,
  setIsAnnounce,
  setHasWonMintWallets,
  setRaffleMintWallet,
  setIsOpenTwitterAutomate,
  setRaffleDiscordUsername,
  setRaffleTwitterUsername,
  setTwitterAutoRequirement,
  setEmailAddr,
  setValidEmailAddr,
  setDiscordLimit,
} = raffleDetailsSlice.actions;

export default raffleDetailsSlice.reducer;
