import { configureChains, createConfig } from 'wagmi';
import { MetaMaskConnector } from 'wagmi/connectors/metaMask';
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect';
import { BitKeepConnector } from './connectors/BitKeep';
import { SafepalConnector } from './connectors/Safepal';
import { TokenpocketConnector } from './connectors/Tokenpocket';
import { publicProvider } from 'wagmi/providers/public';
import * as allChains from 'wagmi/chains';

const { chains, publicClient, webSocketPublicClient } = configureChains(
  Object.values(allChains),
  [publicProvider()]
);

const client = createConfig({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({ chains }),
    new BitKeepConnector({ chains }),
    new SafepalConnector({ chains }),
    new TokenpocketConnector({ chains }),
    new WalletConnectConnector({
      chains,
      options: {
        projectId: 'fc4f248c83f3d0db5c87af1928f82237',
        metadata: {
          name: 'LuckyGo',
          description: 'LuckyGo',
          icons: ['https://luckygo.io/favicon.ico'],
          url: 'https://luckygo.io',
        },
      },
    }),
  ],
  publicClient,
  webSocketPublicClient,
});

export default client;
