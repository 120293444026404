import React, { useCallback, useMemo } from 'react';
import unisatIcon from 'assets/icons/login/unisatIcon.png';
import iconBtc from '@/assets/icons/blockchain/btc.png';
import { useAppSelector } from 'store/hooks';
import classNames from 'classnames';
import { useUniSatAddress } from 'lib/unisatWallet';

const BtcConnect = () => {
  const { onlyConnectType } = useAppSelector((state) => state.web3);
  const { connect: unisatConnect } = useUniSatAddress();

  const divideRender = useCallback(() => {
    if (!onlyConnectType) {
      return (
        <div className="mt-4 flex items-center justify-between">
          <div className="my-3 h-px w-1/2 bg-neutral-600"></div>
          <div className="flex shrink-0 items-center px-3 text-base font-normal text-neutral-400">
            <span>OR</span>
            <img src={iconBtc.src} className="mx-1 w-4" alt="" />
            <span>BTC wallet</span>
          </div>
          <div className="my-3 h-px w-1/2 bg-neutral-600"></div>
        </div>
      );
    }
    return null;
  }, [onlyConnectType]);

  if (onlyConnectType === 'ETH') {
    return null;
  }

  return (
    <div>
      {divideRender()}
      <div
        className={classNames(' grid grid-cols-1 gap-3', {
          'mt-3': onlyConnectType === 'BTC',
          'mt-4': onlyConnectType !== 'BTC',
        })}
      >
        <div
          onClick={unisatConnect}
          className="flex w-full cursor-pointer flex-col items-center justify-center rounded-xl border border-neutral-700 py-3 hover:border-neutral-500"
        >
          <img className="h-8 w-8 rounded-full" src={unisatIcon.src} alt="" />
          <div className="mt-1">UniSat Wallet</div>
        </div>
      </div>
    </div>
  );
};

export default BtcConnect;
