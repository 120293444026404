import request from 'lib/request';

export interface Response {
  code: number;
  data: Data;
  msg: string;
}

export interface Data {
  /**
   * 待签名消息
   */
  message: string;
}

interface Request {
  address: string;
}

export async function getLoginMessage(req: Request) {
  const { address } = req;
  const res = await request<Response>('/session/sign_message', {
    params: { address, ts: Date.now() },
  });
  return res?.data;
}
