import React, { useCallback } from 'react';
import { Connector } from 'wagmi';

import { ArrowUp } from 'lib/lucky-x-icons-2';
import metamaskIcon from 'assets/icons/login/metamask.png';
import walletconnectIcon from 'assets/icons/login/walletConnect.png';
import bitKeepIcon from 'assets/icons/login/bitKeep.png';
import safepalIcon from 'assets/icons/login/safepal.png';
import TokenpocketIcon from 'assets/icons/login/tokenpocket.png';
import { useAppSelector } from 'store/hooks';
import { useToggle } from 'ahooks';
import classNames from 'classnames';

export const iconMap: any = {
  MetaMask: metamaskIcon.src,
  WalletConnect: walletconnectIcon.src,
  BitKeep: bitKeepIcon.src,
  Safepal: safepalIcon.src,
  Tokenpocket: TokenpocketIcon.src,
};

interface IOtherEvmConnectProps {
  connectors: Connector[];
  handleConnect: (connector: any) => void;
}

const OtherEvmConnect: React.FC<IOtherEvmConnectProps> = ({
  connectors,
  handleConnect,
}) => {
  const { onlyConnectType } = useAppSelector((state) => state.web3);
  const [showMore, { toggle: toggleShowMore }] = useToggle(false);

  const divideRender = useCallback(() => {
    if (onlyConnectType !== 'ETH' && showMore) {
      return <div className="mt-4 h-px w-full bg-neutral-600"></div>;
    }
    return null;
  }, [onlyConnectType, showMore]);

  const showMoreBtn = useCallback(() => {
    if (onlyConnectType !== 'ETH') {
      return (
        <div
          className="mt-4 flex cursor-pointer items-center justify-center gap-1 text-neutral-400 hover:text-neutral-400"
          onClick={toggleShowMore}
        >
          <div className=" text-base font-normal">Show More</div>
          <ArrowUp
            size={20}
            className={classNames({
              'rotate-180': !showMore,
            })}
          />
        </div>
      );
    }
    return null;
  }, [onlyConnectType, showMore, toggleShowMore]);

  const connectDom = useCallback(() => {
    if ((!onlyConnectType && showMore) || onlyConnectType === 'ETH') {
      return (
        <div className="mt-4 grid grid-cols-2 gap-3">
          {connectors.slice(1).reverse().map((connector) => (
            <div
              onClick={() => {
                handleConnect(connector);
              }}
              className="flex h-12 cursor-pointer items-center justify-center gap-2 rounded-xl border border-neutral-700 py-3 text-white  hover:border-neutral-500 "
              key={connector.id}
            >
              <img className="w-[22px]" src={iconMap[connector.name]} alt="" />
              <p className="">{connector.name}</p>
            </div>
          ))}
        </div>
      );
    }
    return null;
  }, [connectors, handleConnect, onlyConnectType, showMore]);

  if (onlyConnectType === 'BTC') {
    return null;
  }

  return (
    <div>
      {divideRender()}
      {connectDom()}
      {showMoreBtn()}
    </div>
  );
};

export default OtherEvmConnect;
