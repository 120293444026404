import request from 'lib/request';

import { User } from 'api/user_profile/user_profile_info';

export interface Response {
  code: number;
  data: Data;
  msg: string;
}

export interface Data {
  /**
   * 仅登录接口调用时返回
   */
  token?: null | string;
  user_vo: User;
}

interface Request {
  /**
   * 邀请码
   */
  inviteCode?: null | string;
  /**
   * 签名消息
   */
  message: string;
  /**
   * 签名
   */
  signature: string;
  /**
   * 钱包地址
   */
  address: string;
  zone: string;
}

export async function login(data: Request) {
  const res = await request<Response>('/session/login', {
    method: 'post',
    data,
  });
  return res?.data;
}
