import dayjs from 'dayjs';
import request from 'lib/request';

export interface Request {
  /**
   * 地址
   */
  address: string;
  /**
   * 签名内容
   */
  message: string;
  /**
   * 签名
   */
  signature: string;
  /**
   * 时区
   */
  zone: string;
}

export interface Response {
  code: string;
  data: Data;
  msg: string;
}

export interface Data {
  token: string;
  user: User;
}

export interface User {
  address: string;
  /**
   * 反钓鱼码
   */
  anti_phishing_code: string;
  btc_address: string;
  discord_avatar: string;
  discord_username: string;
  /**
   * 邮箱地址
   */
  email: string;
  google_avatar: string;
  google_email: string;
  /**
   * false：未勾选， true：勾选
   */
  not_remind_mint: boolean;
  /**
   * 订阅列表(枚举，目前有：RAFFLE_WIN , PROJECT_MINT)
   */
  subscriptions: string[];
  twitter_avatar: string;
  twitter_username: string;
  /**
   * twitter 写权限
   */
  twitter_write_permission: boolean;
}

export async function btcLogin(req: Request) {
  const res = await request<Response>('/session/btc_login', {
    data: req,
    method: 'post',
  });

  return res?.data;
}
