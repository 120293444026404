import React from 'react';

import { IconCloseLine, IconLoadindLine } from 'luckybuy-react-icon';
import { useWeb3Modal } from '.';

interface ILoadingProps {}

const Loading: React.FC<ILoadingProps> = () => {
  const { toggleWeb3Modal } = useWeb3Modal();
  return (
    <>
      <div className="flex justify-between py-4">
        <p className="text-2xl font-bold text-white">
          Please sign in your wallet
        </p>
        <div onClick={toggleWeb3Modal} className="h-6 w-6 cursor-pointer">
          <IconCloseLine size={24} color="#fff" />
        </div>
      </div>
      <div className="mt-8 mb-4 text-center">
        <p className="mb-4 text-center text-base font-normal text-white">
          LuckyGo uses this signature to verify that you&apos;re the owner of
          this address.
        </p>
        <div className="my-8 flex justify-center items-center">
          <div className="animate-spin w-6 h-6">
            <IconLoadindLine color="#fff" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Loading;
