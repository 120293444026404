import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { MintWalletList } from 'api/user_profile/user_profile_address_list';

interface UserState {
  inputEmail: string;
  validEmail: number;
  antiCode: string;
  isSubcribeSuccess: boolean;
  isUpdateSubcribe: boolean;
  isNotRemindMint: boolean;
  isUpdateRemindMint: boolean;
  curSubscribedType: string[];
  mintWalletListRaffle: MintWalletList[];
  mintWalletListProfile: MintWalletList[];
}

const initialState: UserState = {
  inputEmail: '',
  validEmail: 0,
  antiCode: '',
  isSubcribeSuccess: false,
  isUpdateSubcribe: false,
  isNotRemindMint: false,
  isUpdateRemindMint: false,
  curSubscribedType: [],
  mintWalletListRaffle: [],
  mintWalletListProfile: [],
};

export const userSlice = createSlice({
  name: 'userSlice',
  initialState,
  reducers: {
    setInputEmail: (state, action: PayloadAction<string>) => {
      state.inputEmail = action.payload;
    },
    setValidEmail: (state, action: PayloadAction<number>) => {
      state.validEmail = action.payload;
    },
    setAntiCode: (state, action: PayloadAction<string>) => {
      state.antiCode = action.payload;
    },
    setSubcribeSuccess: (state, action: PayloadAction<boolean>) => {
      state.isSubcribeSuccess = action.payload;
    },
    setIsUpdateSubcribe: (state, action: PayloadAction<boolean>) => {
      state.isUpdateSubcribe = action.payload;
    },
    setIsNotRemindMint: (state, action: PayloadAction<boolean>) => {
      state.isNotRemindMint = action.payload;
    },
    setIsUpdateRemindMint: (state, action: PayloadAction<boolean>) => {
      state.isUpdateRemindMint = action.payload;
    },
    setCurSubscribedType: (state, action: PayloadAction<string[]>) => {
      state.curSubscribedType = action.payload;
    },
    setMintWalletListRaffle: (
      state,
      action: PayloadAction<MintWalletList[]>
    ) => {
      state.mintWalletListRaffle = action.payload;
    },
    setMintWalletListProfile: (
      state,
      action: PayloadAction<MintWalletList[]>
    ) => {
      state.mintWalletListProfile = action.payload;
    },
  },
});

export const {
  setInputEmail,
  setValidEmail,
  setAntiCode,
  setSubcribeSuccess,
  setIsUpdateSubcribe,
  setIsNotRemindMint,
  setIsUpdateRemindMint,
  setCurSubscribedType,
  setMintWalletListRaffle,
  setMintWalletListProfile,
} = userSlice.actions;

export default userSlice.reducer;
