import { configureStore } from '@reduxjs/toolkit';
import userSlice from './features/userSlice';
import raffleDetailsSlice from './features/raffleDetailsSlice';
import raffleListMeSlice from './features/raffleListMeSlice';
import raffleListSlice from './features/raffleListSlice';
import web3Slice from './features/web3Slice';
import navSlice from './features/navSlice';
import projectListSlice from './features/projectListSlice';
import projectDetailSlice from './features/projectDetailSlice';
import calendarSlice from './features/calendarSlice';
import luckymintPageSlice from './features/luckymintPageSlice';

const reducer = {
  web3: web3Slice,
  raffleListMe: raffleListMeSlice,
  raffleList: raffleListSlice,
  raffleDetails: raffleDetailsSlice,
  user: userSlice,
  nav: navSlice,
  projectList: projectListSlice,
  projectDetail: projectDetailSlice,
  calendar: calendarSlice,
  luckymintPage: luckymintPageSlice,
};

export function makeStore() {
  return configureStore({
    reducer,
  });
}

export const store = makeStore();

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
