import React from 'react';
import { createRoot } from 'react-dom/client';
import { XCircleContainedFill } from 'lib/lucky-x-icons-2';
import { Rubik } from '@next/font/google';

const rubik = Rubik({
  subsets: ['latin'],
  variable: '--font-rubik',
});

const ErrMsg: React.FC<{
  msg: string;
}> = ({ msg }) => {
  return (
    <div
      className={`${rubik.variable} fixed top-20  z-[9999] flex w-full items-center justify-center p-5 font-rubik lg:left-18.5 lg:w-[calc(100vw-74px)] xl_l:left-[220px] xl_l:w-[calc(100vw-220px)]`}
    >
      <div className="flex items-center justify-center overflow-hidden rounded-lg bg-warn-300 px-4 py-3 text-neutral-100">
        <XCircleContainedFill size={24} />
        <p className="ml-2 text-base font-normal">{msg}</p>
      </div>
    </div>
  );
};

export function errMsg(msg: string, second = 3000) {
  const div = document.createElement('div');
  document.body.appendChild(div);
  const root = createRoot(div);
  root.render(<ErrMsg msg={msg} />);
  setTimeout(() => {
    div.remove();
  }, second);
}
