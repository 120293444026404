import { useCallback, useEffect } from 'react';
import jsCookie from 'js-cookie';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setIsLogin, setUserProfileInfo } from 'store/features/web3Slice';
import { useDisconnect } from 'wagmi';
import { clearLocalData, useResetFilterOptions } from './resetData';

export function useUserInfo() {
  const { user, isLogin } = useAppSelector((state) => state.web3);
  const dispatch = useAppDispatch();
  const { disconnect: web3Disconnect } = useDisconnect();
  const { resetFilterOptions } = useResetFilterOptions();

  useEffect(() => {
    const key = process.env.NEXT_PUBLIC_AUTHORIZATION_KEY;
    if (
      ((user.address !== 'null' &&
        user.address !== undefined &&
        user.address !== '') ||
        (user.btc_address !== 'null' &&
          user.btc_address !== undefined &&
          user.btc_address !== '')) &&
      jsCookie.get(key)
    ) {
      dispatch(setIsLogin(true));
    }
  }, [user, dispatch]);

  const disconnect = useCallback(() => {
    web3Disconnect();
    localStorage.removeItem('wc@2:core:0.3//keychain');
    localStorage.removeItem('wc@2:core:0.3//messages');
    localStorage.removeItem('wc@2:ethereum_provider:/chainId');
    localStorage.removeItem('wc@2:client:0.3//proposal');
    localStorage.removeItem('wc@2:universal_provider:/namespaces');
    localStorage.removeItem('wc@2:core:0.3//subscription');
    localStorage.removeItem('wc@2:core:0.3//history');
    localStorage.removeItem('wc@2:client:0.3//session');
    localStorage.removeItem('wc@2:core:0.3//expirer');
    localStorage.removeItem('wc@2:core:0.3//pairing');
    localStorage.removeItem('wc@2:universal_provider:/optionalNamespaces');
    const key = process.env.NEXT_PUBLIC_AUTHORIZATION_KEY;
    dispatch(
      setUserProfileInfo({
        address: '',
        btc_address: '',
        google_email: '',
        google_id: '',
        email: '',
        not_remind_mint: false,
        subscriptions: [],
        discord_id: '',
        discord_username: '',
        twitter_id: '',
        twitter_username: '',
        anti_phishing_code: '',
        twitter_write_permission: false,
      })
    );
    dispatch(setIsLogin(false));
    jsCookie.remove(key);
    const domain = `.${location.hostname.split('.').slice(-2).join('.')}`;
    document.cookie = `Authorization=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=${domain}; path=/;`;
    localStorage.removeItem(
      `${localStorage.getItem('address') as string}-token`
    );
    clearLocalData();
    resetFilterOptions();
    location.reload();
  }, [dispatch, resetFilterOptions, web3Disconnect]);

  return {
    ...user,
    isLogin,
    disconnect,
  };
}
