import request from 'lib/request';

export interface Params {
  address: string;
  message: string;
  signature: string;
}

export interface Response {
  code: number;
  msg: string;
}

export async function evmAddressBinding(params: Params) {
  const res = await request<Response>('/user_profile/eth_address_binding', {
    data: params,
    method: 'post'
  });
  return res?.code;
}
