import '../styles/globals.css';
import type { AppProps, AppContext } from 'next/app';
import Head from 'next/head';
import { Provider } from 'react-redux';
import { makeStore } from 'store';
import client from 'lib/web3Client';
import { useEffect, useMemo } from 'react';
import { deleteCookie } from 'cookies-next';
import { nanoid } from 'nanoid';
import { Rubik } from '@next/font/google';
import dayjs from 'dayjs';
import dayjs_utc from 'dayjs/plugin/utc';
import dayjs_isBetween from 'dayjs/plugin/isBetween';
import dayjs_isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import dayjs_isToday from 'dayjs/plugin/isToday';
import dayjs_duration from 'dayjs/plugin/duration';
import dayjs_customParseFormat from 'dayjs/plugin/customParseFormat';
import dayjs_timezone from 'dayjs/plugin/timezone';
import Web3Modal from 'components/Web3Modal';
import { WagmiConfig } from 'wagmi';
import {
  getUserProfileInfoServer,
  User,
} from 'api/user_profile/user_profile_info';
import { setUserProfileInfo } from 'store/features/web3Slice';
import { enableMapSet } from 'immer';

dayjs.extend(dayjs_utc);
dayjs.extend(dayjs_isBetween);
dayjs.extend(dayjs_duration);
dayjs.extend(dayjs_customParseFormat);
dayjs.extend(dayjs_isSameOrAfter);
dayjs.extend(dayjs_isToday);
dayjs.extend(dayjs_timezone);

if (typeof window !== 'undefined' && !localStorage.getItem('uuid')) {
  localStorage.setItem('uuid', nanoid());
}

interface MyAppProps extends AppProps {
  user: User;
  clearLocalToken: boolean;
}

const rubik = Rubik({
  subsets: ['latin'],
  variable: '--font-rubik',
});

enableMapSet();
export default function App({
  Component,
  pageProps,
  user,
  clearLocalToken,
}: MyAppProps) {
  const store = useMemo(() => makeStore(), []);
  useMemo(() => {
    if (user) {
      store.dispatch(setUserProfileInfo(user));
    }
  }, [user?.address]);

  useEffect(() => {
    if (clearLocalToken) {
      localStorage.removeItem(
        `${String(localStorage.getItem('address'))}-token`
      );
    }
  }, [clearLocalToken]);

  useEffect(() => {
    if (location.href.includes('#/') || location.href.includes('periodId')) {
      history.replaceState('', '', location.origin?.concat('/raffle'));
      open('/raffle', '_self');
    }
    if (location.pathname.split('/').length > 3) {
      location.href = '/404.html';
    }
  }, []);
  return (
    <Provider store={store}>
      <WagmiConfig config={client}>
        <Head>
          <title>LuckyGo</title>
        </Head>
        <main className={`${rubik.variable} font-rubik`}>
          <Component {...pageProps} />
        </main>
        <Web3Modal />
      </WagmiConfig>
    </Provider>
  );
}

App.getInitialProps = async ({ ctx }: AppContext) => {
  const { req, res } = ctx;
  const auth = (req as any)?.cookies?.Authorization;
  let clearLocalToken = false;
  if (!auth) {
    return {
      user: null,
    };
  }
  const user = await getUserProfileInfoServer(auth);
  if (user?.code !== 0) {
    deleteCookie('Authorization', { req, res });
    deleteCookie('Authorization', {
      req,
      res,
      path: '/',
      domain: '.luckygo.io',
    });
    clearLocalToken = true;
  }

  return {
    user: user?.data || null,
    clearLocalToken,
  };
};
