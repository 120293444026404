import { useMemo } from 'react';

export function addressFormat(addr: string) {
  const before = addr?.slice(0, 6);
  const after = addr?.slice(-4);
  return before?.concat('...')?.concat(after);
}

export function useAddressFormat(address: string) {
  const str = useMemo(() => addressFormat(address), [address]);
  return str;
}

export function socialFormat(social: string, type: string) {
  if (type === '#') {
    const before = social?.split(type)[0];
    if (before?.length > 8) {
      return before
        .slice(0, 3)
        .concat('...')
        .concat(before.slice(-3))
        .concat('#')
        .concat(social.split(type)[1]);
    } else {
      return social;
    }
  } else {
    if (social?.length > 8) {
      return type
        .concat(social?.slice(0, 3))
        .concat('...')
        .concat(social?.slice(-3));
    } else {
      return type.concat(social);
    }
  }
}
