import request from 'lib/request';
import umiRequest from 'umi-request';

export interface Response {
  code: number;
  data: User;
  msg: string;
}

/**
 * user
 */
export interface User {
  address: string;
  btc_address?: string;
  /**
   * 反钓鱼码
   */
  anti_phishing_code: string;
  discord_id?: string;
  discord_username: string;
  discord_avatar?: string;
  /**
   * 邮箱地址
   */
  email: string;
  google_email?: string;
  google_id?: string;
  google_avatar?: string;
  /**
   * false：未勾选， true：勾选
   */
  not_remind_mint: boolean;
  /**
   * 订阅列表(枚举，目前有：RAFFLE_WIN , PROJECT_MINT)
   */
  subscriptions: string[];
  twitter_id?: string;
  twitter_username: string;
  twitter_avatar?: string;
  /**
   * twitter 写权限
   */
  twitter_write_permission: boolean;
}

export async function getUserProfileInfo(auth: string) {
  const req = await fetch(
    `${process.env.NEXT_PUBLIC_LUCKYB_T3_V2}/user_profile/info`,
    {
      headers: {
        [process.env.NEXT_PUBLIC_AUTHORIZATION_KEY]: auth,
      },
    }
  );
  const res = req.json().catch(() => null);
  return res;
}

export async function getUserProfileInfoServer(auth: string) {
  const res = await request<Response>('/user_profile/info', {
    headers: {
      [process.env.NEXT_PUBLIC_AUTHORIZATION_KEY]: auth,
    },
  }).catch(() => null);
  return res;
}
