import React, { useCallback } from 'react';
import { useConnect, ConnectorAlreadyConnectedError } from 'wagmi';

import { useWeb3Modal } from '..';
import { X03 } from 'lib/lucky-x-icons-2';
import { Rubik } from '@next/font/google';

import SocialConnect from './SocialConnect';
import MetaMaskConnect from './MetaMaskConnect';
import BtcConnect from './BtcConnect';
import OtherEvmConnect from './OtherEvmConnect';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setWeb3Info } from 'store/features/web3Slice';
import { getAccount } from 'wagmi/actions';

const rubik = Rubik({
  subsets: ['latin'],
  variable: '--font-rubik',
});

interface IWalletProps {
  onLoginAgain: (addr: string | undefined) => void;
}

const Wallet: React.FC<IWalletProps> = ({ onLoginAgain }) => {
  const dispatch = useAppDispatch();
  const { toggleWeb3Modal } = useWeb3Modal();
  const { onlyConnectType } = useAppSelector((state) => state.web3);
  const { connectAsync, connectors } = useConnect();
  const handleConnect = useCallback(
    (connector: any) => {
      dispatch(setWeb3Info({ loginLoading: true }));
      connectAsync({ connector })
        .then((res) => {
          if (res.account) {
            if (connector.name === 'WalletConnect') {
              setTimeout(() => {
                onLoginAgain(res.account);
              }, 5000);
            } else {
              onLoginAgain(res.account);
            }
          }
        })
        .catch((err) => {
          if (err instanceof ConnectorAlreadyConnectedError) {
            const { address } = getAccount();
            onLoginAgain(address);
          } else {
            dispatch(setWeb3Info({ loginLoading: false }));
          }
        });
    },
    [connectAsync, onLoginAgain, dispatch]
  );

  return (
    <div
      className={`${rubik.variable} font-rubik text-sm font-medium text-white`}
    >
      <div className="flex items-start justify-between pb-3">
        <p className="text-2xl font-bold italic leading-8 text-white">
          Connect {onlyConnectType} Wallet
        </p>
        <div onClick={toggleWeb3Modal} className="h-6 w-6 cursor-pointer">
          <X03 size={32} className="text-neutral-400 hover:text-neutral-100" />
        </div>
      </div>
      <MetaMaskConnect connectors={connectors} handleConnect={handleConnect} />
      <SocialConnect />
      <BtcConnect />
      <OtherEvmConnect connectors={connectors} handleConnect={handleConnect} />
    </div>
  );
};

export default Wallet;
