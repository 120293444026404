import React, { useCallback, useMemo } from 'react';
import { useAccount, useSignMessage } from 'wagmi';
import jsCookie from 'js-cookie';
import dayjs from 'dayjs';
import { getAccount } from 'wagmi/actions';
import Wallet from './Wallet';
import Loading from './Loading';
import Error from './Error';
import { useDebounceFn, useDocumentVisibility, useUpdateEffect } from 'ahooks';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  setOpenWeb3Modal,
  setUserProfileInfo,
  setWeb3Info,
  web3ModalToggle,
} from 'store/features/web3Slice';
import { getLoginMessage } from 'api/session/session_sign_message';
import { login } from 'api/session/session_login';
import { clearLocalData, useResetFilterOptions } from 'lib/resetData';
import { getUserProfileInfo } from 'api/user_profile/user_profile_info';
import { btcLogin } from 'api/session/session_btc_login';
import { btcAddressBinding } from 'api/user_profile/user_profile_btc_address_binding';
import { useUserInfo } from 'lib/user';
import { evmAddressBinding } from 'api/user_profile/user_profile_eth_address_binding';
import { errMsgModal } from './ErrMsgModal';

interface IWeb3ModalProps {}

export function useWeb3Modal() {
  const { web3ModalOpen } = useAppSelector((state) => state.web3);
  const dispatch = useAppDispatch();
  const toggleWeb3Modal = useCallback(() => {
    dispatch(web3ModalToggle());
  }, [dispatch]);
  return { open: web3ModalOpen, toggleWeb3Modal };
}

const Web3Modal: React.FC<IWeb3ModalProps> = () => {
  const { open } = useWeb3Modal();
  const documentVisibility = useDocumentVisibility();
  const { address } = useAccount();

  const dispatch = useAppDispatch();
  const { error: ErrorSignMessage, signMessageAsync } = useSignMessage();
  const { resetFilterOptions } = useResetFilterOptions();
  const { isLogin, btc_address, address: loginAddress } = useUserInfo();
  const { loginLoading } = useAppSelector((state) => state.web3);
  const isEvmBind = useMemo(() => {
    return isLogin && !loginAddress;
  }, [isLogin, loginAddress]);

  const handleSignMessage = useCallback(
    async (address: string | undefined) => {
      const { connector } = getAccount();
      const key = `${address?.toLocaleLowerCase()}-token`;
      const res = await getLoginMessage({
        address: (address || '').toLowerCase(),
      });
      const signMessageData = await signMessageAsync({
        message: res.message,
      }).catch(() => null);
      if (!signMessageData) {
        dispatch(setWeb3Info({ loginLoading: false }));
        return;
      }
      if (isEvmBind) {
        const bindStuts = await evmAddressBinding({
          address: (address || '').toLowerCase(),
          message: res.message,
          signature: signMessageData,
        });
        if (bindStuts === 0) {
          location.reload();
        } else {
          errMsgModal(address || '');
        }
        dispatch(setWeb3Info({ loginLoading: false }));
      } else {
        const loginRes = await login({
          message: res.message,
          signature: signMessageData,
          address: (address || '').toLowerCase(),
          zone: `UTC${dayjs().utcOffset() >= 0 ? '+' : '-'}${dayjs
            .duration(dayjs().utcOffset(), 'minute')
            .format('HH:mm')
            .replace('-', '')}`,
        });
        jsCookie.set(
          process.env.NEXT_PUBLIC_AUTHORIZATION_KEY,
          `Bearer ${loginRes?.token}`,
          {
            expires: 6,
          }
        );
        localStorage.setItem(key, loginRes?.token as string);
        localStorage.setItem(
          process.env.NEXT_PUBLIC_WALLET_CONNECTOR_NAME as any,
          connector?.name || ''
        );
        dispatch(setUserProfileInfo(loginRes?.user_vo));
        dispatch(setWeb3Info({ loginLoading: false }));
        dispatch(setOpenWeb3Modal(false));
        location.reload();
      }
    },
    [signMessageAsync, dispatch, isEvmBind]
  );

  const handleSign = useCallback(
    async (address: string | undefined) => {
      const key = `${address?.toLocaleLowerCase()}-token`;
      if (
        !!localStorage.getItem(key) &&
        localStorage.getItem(key) !== 'undefined'
      ) {
        const auth = `Bearer ${localStorage.getItem(key)}`;
        const res = await getUserProfileInfo(auth || '');
        if (!!res?.data) {
          jsCookie.set(process.env.NEXT_PUBLIC_AUTHORIZATION_KEY, auth, {
            expires: 6,
          });
        } else {
          jsCookie.remove(process.env.NEXT_PUBLIC_AUTHORIZATION_KEY);
          localStorage.removeItem(key);
        }
        clearLocalData();
        resetFilterOptions();
        setTimeout(() => {
          location.reload();
        }, 1000);
      } else {
        localStorage.removeItem(key);
        handleSignMessage(address);
      }
    },
    [handleSignMessage, resetFilterOptions]
  );
  const contentDom = useMemo(() => {
    if (!!ErrorSignMessage?.message?.includes('user rejected signing')) {
      return (
        <Error
          isErrorSignMessage={ErrorSignMessage !== null}
          onRetry={handleSignMessage}
        />
      );
    }
    if (loginLoading) {
      return <Loading />;
    }
    return <Wallet onLoginAgain={handleSignMessage} />;
  }, [ErrorSignMessage, loginLoading, handleSignMessage]);

  const isBind = useMemo(() => {
    return isLogin && !btc_address;
  }, [isLogin, btc_address]);
  const { unisatAddress, xcerseAddress } = useAppSelector(
    (state) => state.web3
  );

  const setBtcLoginInfo = useCallback(
    (token: string) => {
      jsCookie.set(
        process.env.NEXT_PUBLIC_AUTHORIZATION_KEY,
        `Bearer ${token}`,
        {
          expires: 6,
        }
      );
      clearLocalData();
      resetFilterOptions();
      location.reload();
    },
    [resetFilterOptions]
  );

  const loginUnisatAndXverse = useCallback(
    async (address: string) => {
      const msg = await getLoginMessage({ address });
      if (unisatAddress) {
        const unisat = (window as any).unisat;
        try {
          const sign = await unisat.signMessage(msg.message).catch(() => null);
          if (!sign) {
            dispatch(setWeb3Info({ unisatAddress: '' }));
            return;
          }
          if (isBind) {
            const bindStatus = await btcAddressBinding({
              address,
              message: msg.message,
              signature: sign,
            });
            if (bindStatus === 0) {
              location.reload();
            } else {
              errMsgModal(address || '');
            }
          } else {
            const res = await btcLogin({
              message: msg.message,
              signature: sign,
              address: (address || '').toLowerCase(),
              zone: `UTC${dayjs().utcOffset() >= 0 ? '+' : '-'}${dayjs
                .duration(dayjs().utcOffset(), 'minute')
                .format('HH:mm')
                .replace('-', '')}`,
            });
            if (!res?.token) {
              dispatch(setWeb3Info({ unisatAddress: '' }));
            }
            setBtcLoginInfo(res.token);
          }
        } catch (e) {
          console.log(e);
        }
      }
    },
    [unisatAddress, isBind, setBtcLoginInfo, dispatch]
  );

  useUpdateEffect(() => {
    if (unisatAddress) {
      loginUnisatAndXverse(unisatAddress);
    }
  }, [unisatAddress]);

  const debouncedValue = useDebounceFn(handleSign, {
    wait: 2000,
  });

  useUpdateEffect(() => {
    if (documentVisibility === 'visible' && address && loginAddress) {
      debouncedValue.run(address);
    }
  }, [address]);

  if (!open) {
    return null;
  }
  return (
    <>
      <div className="fixed left-0 top-0 z-[99] flex h-full w-full items-center justify-center backdrop-blur">
        <div className="max-h-[90vh] w-96 overflow-hidden overflow-y-scroll rounded-2xl bg-neutral-900 px-7 py-8 md:w-[456px]">
          {contentDom}
        </div>
      </div>
    </>
  );
};

export default Web3Modal;
