import { Modal } from 'antd';
import { X03 } from 'lib/lucky-x-icons-2';
import { addressFormat } from 'lib/nicknameFormat';
import { useState } from 'react';
import { createRoot } from 'react-dom/client';

const ErrMsgModal: React.FC<{
  address: string;
  onClose: () => void;
}> = ({ address, onClose }) => {
  const [open, setOpen] = useState(true);
  return (
    <Modal
      title="Account Error"
      closeIcon={
        <X03
          className="-mr-[6px] cursor-pointer text-neutral-400 hover:text-neutral-300"
          size={32}
          onClick={() => {
            setOpen(false);
            onClose();
          }}
        />
      }
      open={open}
      footer={
        <div
          className="w-full rounded-[30px] !bg-primary-500 px-8 py-4 text-center text-lg font-normal text-white hover:bg-primary-600"
          onClick={() => {
            setOpen(false);
            onClose();
          }}
        >
          Got it
        </div>
      }
    >
      <div className="whitespace-pre-wrap text-base font-normal text-white">
        <p>
          The address cannot be connected repeatedly. The address you want to
          connect to has already been connected to another account.
        </p>
        <p className="my-5 text-primary-500">{addressFormat(address)}</p>
        <p>
          If you want to use another account, simply switch to it in your
          wallet.
        </p>
      </div>
    </Modal>
  );
};

export function errMsgModal(address: string) {
  const div = document.createElement('div');
  document.body.appendChild(div);
  const root = createRoot(div);
  root.render(
    <ErrMsgModal
      address={address}
      onClose={() => {
        div.remove();
      }}
    />
  );
}
