import { extend } from 'umi-request';
import jsCookie from 'js-cookie';
import { errMsg } from '@/components/PageElement/ErrorMessage';
import { clearLocalData } from './resetData';
import { isEmpty, get } from 'lodash';

const request = extend({
  prefix:
    typeof window === 'undefined'
      ? process.env.NEXT_PUBLIC_LUCKYB_T3_V2_NODE
      : process.env.NEXT_PUBLIC_LUCKYB_T3_V2,
  timeout: 5000,
  errorHandler(error) {
    if (error.response) {
      console.log(error.response.status);
      console.log(error.response.headers);
      console.log(error.data);
      console.log(error.request);
    } else {
      console.log(error.message);
    }
    throw error;
  },
});

request.interceptors.request.use((url, options) => {
  let auth = '';
  const key = process.env.NEXT_PUBLIC_AUTHORIZATION_KEY as any;
  if (typeof window !== 'undefined') {
    auth = jsCookie.get(key) || '';
    if (isEmpty(get(options, `headers.${key}`))) {
      options.headers = {
        ...options.headers,
        [key]: `${auth}`,
      } as any;
    }
  }
  return {
    url,
    options,
  };
});

request.interceptors.response.use(async (response) => {
  if (response.url.indexOf('/api/v1/event') > -1) {
    return response;
  }
  const data = await response
    .clone()
    .json()
    .catch(() => null);
  if (data?.code !== 0) {
    console.error(JSON.stringify(data), response.url);
  }
  const errMsgIgnoreMap = [
    '/record/period/tx/number',
    'user/social',
    'user/info',
    'record/period',
    'period/detail',
    '/raffle/register',
    '/raffle/list/me',
    '/raffle/stat/me',
    '/raffle/list',
    '/user_profile/create_address',
    '/user_profile/set_default_address',
    '/user_profile/delete_address',
    'raffle/announce',
    '/raffle_entry/mint_wallet',
    '/project/track',
    '/project/untrack',
    '/user_profile/not_remind_mint_again',
    '/project/add_whitelist',
    '/project/remove_whitelist',
    '/user_profile/twitter_rebinding',
    '/user_profile/discord_rebinding',
  ];

  if (typeof window !== 'undefined' && data?.code === -4) {
    const key = process.env.NEXT_PUBLIC_AUTHORIZATION_KEY;
    jsCookie.remove(key);
    localStorage.removeItem(
      `${localStorage.getItem('address') as string}-token`
    );
    clearLocalData();
    setTimeout(() => {
      location.reload();
    }, 500);
  }
  if (
    typeof window !== 'undefined' &&
    data?.code !== 0 &&
    data?.msg &&
    errMsgIgnoreMap.findIndex((item) => response.url.indexOf(item) > -1) === -1
  ) {
    errMsg(data?.msg);
  }
  return response;
});

export default request;
