import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RaffleList } from 'api/raffle/raffle_list';
import { CampaignList } from 'api/community/campaign_list_dc_me';
import { ProjectList } from 'api/project/project_tracking_unmint';

interface RaffleListState {
  raffleListInfo: RaffleList;
  raffleListCurPage: number;
  isChangeRaffleFilter: number;
  isClearAll: boolean;
  searchName: string;
  communityType: string;
  projectType: string;
  sortType: string;
  freeMint: string;
  loading: boolean;
  scrollLoading: boolean;
  campaignList: CampaignList[];
  myPartialCampaignIds: string[];
  projectList: ProjectList[];
  myParticialProjectIds: string[];
}

const initialState: RaffleListState = {
  raffleListInfo: {
    has_next: false,
    list: [],
    page: 1,
    size: 20,
    total: -1,
  },
  raffleListCurPage: 1,
  isChangeRaffleFilter: -1,
  isClearAll: false,
  searchName: '',
  communityType: '',
  projectType: '',
  sortType: '',
  freeMint: '',
  loading: false,
  scrollLoading: false,
  campaignList: [],
  myPartialCampaignIds: [],
  projectList: [],
  myParticialProjectIds: [],
};

export const raffleListSlice = createSlice({
  name: 'raffleListSlice',
  initialState,
  reducers: {
    setRaffleListCurPage: (state, action: PayloadAction<number>) => {
      state.raffleListCurPage = action.payload;
    },
    setRaffleListInfo: (state, action: PayloadAction<RaffleList>) => {
      state.raffleListInfo.has_next = action.payload?.has_next;
      state.raffleListInfo.list =
        state.raffleListCurPage === 1
          ? action.payload?.list || []
          : state.raffleListCurPage === action.payload?.page
          ? state.raffleListInfo.list.concat(action.payload?.list)
          : action.payload?.list;
      state.raffleListInfo.page = action.payload?.page;
      state.raffleListInfo.total = action.payload?.total;
    },
    setChangeRaffleFilter: (state, action: PayloadAction<number>) => {
      state.isChangeRaffleFilter = action.payload;
    },
    setIsClearAll: (state, action: PayloadAction<boolean>) => {
      state.isClearAll = action.payload;
    },
    setSearchName: (state, action: PayloadAction<string>) => {
      state.searchName = action.payload;
    },
    setCommunityType: (state, action: PayloadAction<string>) => {
      state.communityType = action.payload;
    },
    setProjectType: (state, action: PayloadAction<string>) => {
      state.projectType = action.payload;
    },
    setSortType: (state, action: PayloadAction<string>) => {
      state.sortType = action.payload;
    },
    setFreeMint: (state, action: PayloadAction<string>) => {
      state.freeMint = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setScrollLoading: (state, action: PayloadAction<boolean>) => {
      state.scrollLoading = action.payload;
    },
    setCampaignList: (state, action: PayloadAction<CampaignList[]>) => {
      state.campaignList = action.payload;
    },
    setMyPartialCampaignIds: (state, action: PayloadAction<string[]>) => {
      state.myPartialCampaignIds = action.payload;
    },
    setProjectList: (state, action: PayloadAction<ProjectList[]>) => {
      state.projectList = action.payload;
    },
    setMyPartialProjectIds: (state, action: PayloadAction<string[]>) => {
      state.myParticialProjectIds = action.payload;
    },
  },
});

export const {
  setRaffleListInfo,
  setRaffleListCurPage,
  setChangeRaffleFilter,
  setIsClearAll,
  setSearchName,
  setCommunityType,
  setProjectType,
  setSortType,
  setFreeMint,
  setLoading,
  setScrollLoading,
  setCampaignList,
  setMyPartialCampaignIds,
  setProjectList,
  setMyPartialProjectIds,
} = raffleListSlice.actions;

export default raffleListSlice.reducer;
