import React, { useCallback } from 'react';

import { DiscordFillBrand, TwitterFillBrand } from 'lib/lucky-x-icons-2';
import { sessionTwitterOauthUrl } from 'api/session/session_twitter_oauth_url';
import { sessionDiscordOauthUrl } from 'api/session/session_discord__oauth_url';
import { useAppSelector } from 'store/hooks';

const SocialConnect = () => {
  const { onlyConnectType } = useAppSelector((state) => state.web3);

  const handleLoginWithSocial = useCallback((type: string) => {
    if (type === 'TWITTER') {
      sessionTwitterOauthUrl();
    } else if (type === 'DISCORD') {
      sessionDiscordOauthUrl();
    }
    localStorage.setItem('loginSocialType', type);
  }, []);

  if (!!onlyConnectType) {
    return null;
  }

  return (
    <div className="mt-4 grid h-12 grid-cols-2 gap-3">
      <div
        className="flex  cursor-pointer items-center justify-center gap-2 rounded-xl border border-neutral-700  hover:border-neutral-500"
        onClick={() => {
          handleLoginWithSocial('DISCORD');
        }}
      >
        <DiscordFillBrand size={24} color="#5865F2" />
        <div>Discord</div>
      </div>

      <div
        className=" flex cursor-pointer items-center justify-center gap-2 rounded-xl border border-neutral-700  hover:border-neutral-500"
        onClick={() => {
          handleLoginWithSocial('TWITTER');
        }}
      >
        <TwitterFillBrand size={24} color="#00A3FF" />
        <div>Twitter</div>
      </div>
    </div>
  );
};

export default SocialConnect;
