import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { LotRoundDetailData } from 'api/luckymint/lot_round';
import { UserTicketDetailData } from 'api/luckymint/user_ticket_detail';

interface State {
  roundDetail: LotRoundDetailData | null;
  userTicketDetail: UserTicketDetailData | null;
  updataTicketDataMsg: number;
}

const initialState: State = {
  roundDetail: null,
  userTicketDetail: null,
  updataTicketDataMsg: 0,
};

export const luckymintPageSlice = createSlice({
  name: 'luckymintPageSlice',
  initialState,
  reducers: {
    setLuckymintPageSliceData: (
      state,
      action: PayloadAction<Partial<State>>
    ) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { setLuckymintPageSliceData } = luckymintPageSlice.actions;

export default luckymintPageSlice.reducer;
