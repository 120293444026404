import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface NavState {
  trackedMessage: string;
  trackedDataDoneMessage: string;
}

const initialState: NavState = {
  trackedMessage: '',
  trackedDataDoneMessage: '',
};

export const calendarSlice = createSlice({
  name: 'navSlice',
  initialState,
  reducers: {
    sendTrackedMessage: (state, action: PayloadAction<string>) => {
      state.trackedMessage = action.payload;
    },
    sendTrackedDataDoneMessage: (state, action: PayloadAction<string>) => {
      state.trackedDataDoneMessage = action.payload;
    },
  },
});

export const { sendTrackedMessage, sendTrackedDataDoneMessage } =
  calendarSlice.actions;

export default calendarSlice.reducer;
