import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { ActiveRaffle, ProjectWhitelist } from 'api/project/project_detail';

interface ProjectDetailState {
  projectWhitelist: ProjectWhitelist[];
  projectActiveRaffle: ActiveRaffle[];
  curExpandProject: number;
  curOperateProject: number;
  loading: boolean;
}

const initialState: ProjectDetailState = {
  projectWhitelist: [],
  projectActiveRaffle: [],
  curExpandProject: 0,
  curOperateProject: 0,
  loading: false,
};

export const projectDetailSlice = createSlice({
  name: 'projectDetailSlice',
  initialState,
  reducers: {
    setProjectWhitelist: (state, action: PayloadAction<ProjectWhitelist[]>) => {
      state.projectWhitelist = action.payload;
    },
    setProjectActiveRaffle: (state, action: PayloadAction<ActiveRaffle[]>) => {
      state.projectActiveRaffle = action.payload;
    },
    setCurExpandProject: (state, action: PayloadAction<number>) => {
      state.curExpandProject = action.payload;
    },
    setCurOperateProject: (state, action: PayloadAction<number>) => {
      state.curOperateProject = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
});

export const {
  setProjectWhitelist,
  setProjectActiveRaffle,
  setCurExpandProject,
  setCurOperateProject,
  setLoading,
} = projectDetailSlice.actions;

export default projectDetailSlice.reducer;
