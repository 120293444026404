import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { MyRaffleList } from 'api/raffle/raffle_list_me';
import { RaffleStatMe } from 'api/raffle/raffle_stat_me';

interface RaffleListMeState {
  raffleListMeInfo: MyRaffleList;
  raffleListMeCurPage: number;
  statusType: string;
  raffleStatMe: RaffleStatMe;
  loading: boolean;
  scrollLoading: boolean;
}

const initialState: RaffleListMeState = {
  raffleListMeInfo: {
    has_next: false,
    list: [],
    page: 1,
    size: 20,
    total: -1,
  },
  raffleListMeCurPage: 1,
  statusType: 'joined',
  raffleStatMe: {
    joined_count: 0,
    pending_count: 0,
    won_count: 0,
    lost_count: 0,
  },
  loading: false,
  scrollLoading: false,
};

export const raffleListMeSlice = createSlice({
  name: 'raffleListMeSlice',
  initialState,
  reducers: {
    setRaffleListMeCurPage: (state, action: PayloadAction<number>) => {
      state.raffleListMeCurPage = action.payload;
    },
    setRaffleListMeInfo: (state, action: PayloadAction<MyRaffleList>) => {
      state.raffleListMeInfo.has_next = action.payload?.has_next;
      state.raffleListMeInfo.list =
        state.raffleListMeCurPage === 1
          ? action.payload?.list
          : state.raffleListMeInfo.list.concat(action.payload?.list);
      state.raffleListMeInfo.page = action.payload?.page;
      state.raffleListMeInfo.total = action.payload?.total;
    },
    setStatusType: (state, action: PayloadAction<string>) => {
      state.statusType = action.payload;
    },
    setRaffleStatMe: (state, action: PayloadAction<RaffleStatMe>) => {
      state.raffleStatMe = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setScrollLoading: (state, action: PayloadAction<boolean>) => {
      state.scrollLoading = action.payload;
    },
  },
});

export const {
  setRaffleListMeInfo,
  setRaffleListMeCurPage,
  setStatusType,
  setRaffleStatMe,
  setLoading,
  setScrollLoading,
} = raffleListMeSlice.actions;

export default raffleListMeSlice.reducer;
