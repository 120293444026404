import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { ProjectList } from 'api/project/project_search';

interface ProjectListState {
  projectListInfo: ProjectList;
  total: number;
  projectListNextPage: number;
  isChangeProjecFilter: number;
  searchName: string;
  sortType: string;
  freeMint: string;
  loading: boolean;
  scrollLoading: boolean;
  isScroll: boolean;
  projectChangeMsg: number;
}

const initialState: ProjectListState = {
  projectListInfo: {
    has_next: false,
    list: [],
    page: 1,
    size: 20,
  },
  total: -1,
  projectListNextPage: 1,
  isChangeProjecFilter: -1,
  searchName: '',
  sortType: '',
  freeMint: '',
  loading: false,
  scrollLoading: false,
  isScroll: false,
  projectChangeMsg: 0,
};

export const projectListSlice = createSlice({
  name: 'projectListSlice',
  initialState,
  reducers: {
    setProjecListNextPage: (state, action: PayloadAction<number>) => {
      state.projectListNextPage = action.payload;
    },
    setProjecListTotal: (state, action: PayloadAction<number>) => {
      state.total = action.payload;
    },
    setProjecListInfo: (state, action: PayloadAction<ProjectList>) => {
      state.projectListInfo.has_next = action.payload?.has_next;
      state.projectListInfo.list =
        action.payload?.page === 1
          ? action.payload?.list || []
          : state.projectListInfo.list.concat(action.payload?.list);
      state.projectListInfo.page = action.payload?.page;
    },
    setChangeProjecFilter: (state, action: PayloadAction<number>) => {
      state.isChangeProjecFilter = action.payload;
    },
    setSearchName: (state, action: PayloadAction<string>) => {
      state.searchName = action.payload;
    },
    setSortType: (state, action: PayloadAction<string>) => {
      state.sortType = action.payload;
    },
    setFreeMint: (state, action: PayloadAction<string>) => {
      state.freeMint = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setScrollLoading: (state, action: PayloadAction<boolean>) => {
      state.scrollLoading = action.payload;
    },
    setIsScroll: (state, action: PayloadAction<boolean>) => {
      state.isScroll = action.payload;
    },
    sendProjectChangeMsg: (state, action: PayloadAction<number>) => {
      state.projectChangeMsg = action.payload;
    },
  },
});

export const {
  setChangeProjecFilter,
  setProjecListNextPage,
  setProjecListInfo,
  setProjecListTotal,
  setSearchName,
  setSortType,
  setFreeMint,
  setLoading,
  setScrollLoading,
  setIsScroll,
  sendProjectChangeMsg,
} = projectListSlice.actions;

export default projectListSlice.reducer;
