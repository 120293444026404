import React from 'react';
import { Connector } from 'wagmi';

import metamaskIcon from 'assets/icons/login/metamask.png';
import { useAppSelector } from 'store/hooks';

export function isMobileBrowser() {
  return /(iPhone|iPod|iPad|Android|BlackBerry|IEMobile|Opera Mini)/i.test(
    navigator.userAgent
  );
}

interface IMetaMaskConnectProps {
  connectors: Connector[];
  handleConnect: (connector: any) => void;
}

const MetaMaskConnect: React.FC<IMetaMaskConnectProps> = ({
  connectors,
  handleConnect,
}) => {
  const { onlyConnectType } = useAppSelector((state) => state.web3);

  if (onlyConnectType === 'BTC') {
    return null;
  }

  return (
    <div>
      {connectors.slice(0, 1).map((connector, index) => (
        <div
          onClick={() => {
            if (
              connector.name === 'MetaMask' &&
              isMobileBrowser() &&
              !window?.ethereum?.isMetaMask
            ) {
              window.open('https://metamask.app.link/dapp/' + location.href);
            } else {
              handleConnect(connector);
            }
          }}
          className="mt-3 flex w-full cursor-pointer flex-col items-center justify-center rounded-xl border border-neutral-700 py-3 hover:border-neutral-500"
          key={connector.id}
        >
          <img className="h-8 w-8" src={metamaskIcon.src} alt="" />
          <p className="mt-1">{connector.name}</p>
        </div>
      ))}
    </div>
  );
};

export default MetaMaskConnect;
