import React, { useCallback } from 'react';
import classNames from 'classnames';
import { useWeb3Modal } from '.';
import { useAccount } from 'wagmi';
import { X03 } from 'lib/lucky-x-icons-2';
import { Rubik } from '@next/font/google';

interface IAppProps {
  isErrorSignMessage: boolean;
  onRetry: (addr: string | undefined) => void;
}

const rubik = Rubik({
  subsets: ['latin'],
  variable: '--font-rubik',
});

const Error: React.FC<IAppProps> = ({ isErrorSignMessage, onRetry }) => {
  const { toggleWeb3Modal } = useWeb3Modal();
  const { address } = useAccount();

  const closeRetry = useCallback(() => {
    toggleWeb3Modal();
    location.reload();
  }, [toggleWeb3Modal]);

  return (
    <div className={`${rubik.variable} font-rubik`}>
      <div className="flex justify-between">
        <p
          className={classNames('text-2xl font-bold text-white', {
            'text-[#E34D59]': isErrorSignMessage,
          })}
        >
          Signature rejected
        </p>
        <div onClick={closeRetry} className="h-6 w-6 cursor-pointer">
          <X03 size={32} className="text-neutral-400 hover:text-neutral-100" />
        </div>
      </div>
      <div className="mt-8 text-center">
        <p className="mb-4 text-center text-base font-normal text-white">
          LuckyGo uses this signature to verify that you&apos;re the owner of
          this address.
        </p>
        <div
          onClick={() => {
            onRetry(address);
          }}
          className="flex h-10 cursor-pointer items-center justify-center rounded-md bg-[#98FF59] text-base font-normal text-[#111111]"
        >
          Retry
        </div>
      </div>
    </div>
  );
};

export default Error;
