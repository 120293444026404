import dayjs from 'dayjs';
import request from 'lib/request';

export interface Response {
  code: string;
  data: Data;
  msg: string;
}

export interface Data {
  oauth_url: string;
}

export async function sessionTwitterOauthUrl() {
  const res = await request<Response>('/session/twitter_oauth_url', {
    params: {
      redirect_url: location.href,
      zone: `UTC${dayjs().utcOffset() >= 0 ? '+' : '-'}${dayjs
        .duration(dayjs().utcOffset(), 'minute')
        .format('HH:mm')
        .replace('-', '')}`,
    },
  });
  if (res?.data?.oauth_url) {
    location.href = res?.data?.oauth_url;
  }
}
