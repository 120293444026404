import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { User } from 'api/user_profile/user_profile_info';

interface Web3State {
  web3ModalOpen: boolean;
  user: User;
  isLogin: boolean;
  unisatAddress: string;
  xcerseAddress: string;
  onlyConnectType: string;
  loginLoading: boolean;
}

const initialState: Web3State = {
  web3ModalOpen: false,
  user: {
    address: '',
    btc_address: '',
    google_email: '',
    google_id: '',
    email: '',
    not_remind_mint: false,
    subscriptions: [],
    discord_id: '',
    discord_username: '',
    twitter_id: '',
    twitter_username: '',
    anti_phishing_code: '',
    twitter_write_permission: false,
  },
  unisatAddress: '',
  xcerseAddress: '',
  isLogin: false,
  onlyConnectType: '',
  loginLoading: false,
};

export const web3Slice = createSlice({
  name: 'web3Slice',
  initialState,
  reducers: {
    web3ModalToggle: (state) => {
      state.web3ModalOpen = !state.web3ModalOpen;
    },
    setUserProfileInfo: (state, action: PayloadAction<User>) => {
      if (action.payload) {
        state.user = action.payload;
      }
      if (action.payload?.address || action.payload?.btc_address) {
        state.isLogin = true;
      } else {
        state.isLogin = false;
      }
    },
    setIsLogin: (state, action: PayloadAction<boolean>) => {
      state.isLogin = action.payload;
    },
    setOpenWeb3Modal: (state, action: PayloadAction<boolean>) => {
      state.web3ModalOpen = action.payload;
    },
    setWeb3Info: (state, action: PayloadAction<Partial<Web3State>>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    setOnlyConnectType: (state, action: PayloadAction<string>) => {
      state.onlyConnectType = action.payload;
    },
  },
});

export const {
  web3ModalToggle,
  setUserProfileInfo,
  setIsLogin,
  setOpenWeb3Modal,
  setWeb3Info,
  setOnlyConnectType,
} = web3Slice.actions;

export default web3Slice.reducer;
