import { useCallback, useEffect } from 'react';
import { useAddressFormat } from './nicknameFormat';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setWeb3Info } from 'store/features/web3Slice';
import { errMsg } from '@/components/PageElement/ErrorMessage';

export function useUniSatAddress() {
  const { unisatAddress } = useAppSelector((state) => state.web3);
  const name = useAddressFormat(unisatAddress);
  const disptch = useAppDispatch();

  const connect = useCallback(async () => {
    const unisat = (window as any).unisat;
    if (!unisat) {
      errMsg('You haven\'t installed the UniSat Wallet yet');
      return;
    }
    const result = await unisat.requestAccounts();
    disptch(
      setWeb3Info({
        unisatAddress: result[0],
      })
    );
  }, [disptch]);

  return {
    unisatAddress,
    name,
    connect,
  };
}

export function useUniSatAccountsChanged() {
  const dispatch = useAppDispatch();
  const handleAccountsChanged = useCallback(
    (_accounts: string[]) => {
      if (_accounts.length > 0) {
        dispatch(
          setWeb3Info({
            unisatAddress: _accounts[0],
          })
        );
      } else {
        dispatch(
          setWeb3Info({
            unisatAddress: '',
          })
        );
      }
    },
    [dispatch]
  );
  useEffect(() => {
    const unisat = (window as any).unisat;
    if (unisat) {
    } else {
      return;
    }

    unisat.getAccounts().then((accounts: string[]) => {
      handleAccountsChanged(accounts);
    });

    unisat.on('accountsChanged', handleAccountsChanged);

    return () => {
      unisat.removeListener('accountsChanged', handleAccountsChanged);
    };
  }, []);
}
