import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface NavState {
  openMenus: boolean;
  openMore: boolean;
  screenWidth: number;
}

const initialState: NavState = {
  openMenus: false,
  openMore: false,
  screenWidth: 0,
};

export const navSlice = createSlice({
  name: 'navSlice',
  initialState,
  reducers: {
    setOpenMenus: (state, action: PayloadAction<boolean>) => {
      state.openMenus = action.payload;
    },
    setOpenMore: (state, action: PayloadAction<boolean>) => {
      state.openMore = action.payload;
    },
    setScreenWidth: (state, action: PayloadAction<number>) => {
      state.screenWidth = action.payload;
    },
  },
});

export const { setOpenMenus, setOpenMore, setScreenWidth } = navSlice.actions;

export default navSlice.reducer;
